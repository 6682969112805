import React, { useEffect, useState } from "react";
import '../i18n';
import { useTranslation, Trans } from 'react-i18next';
import { useDropzone } from "react-dropzone";
// import * as firebase from "firebase/app";
import { db, storage } from "../firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import tags_image from "../Assets/Images/tags.png";
import delete_icon from "../Assets/Images/delete.svg";

function FormStep2({
  formData,
  setFormData,
  setIsStepValidate,
  formValidation,
  setFormValidation,
}) {
  const { t } = useTranslation();

  const additionalTagsMax = 7;
  const tags = [
    t('formStep2Tag1'),
    t('formStep2Tag2'),
    t('formStep2Tag3'),
    t('formStep2Tag4'),
    t('formStep2Tag5'),
    t('formStep2Tag6'),
    t('formStep2Tag7'),
  ];
  const [logo, setLogo] = useState(formData.logo);
  const [isMessageValid, setIsMessageValid] = useState(
    formData.message.length > 0 ? true : false
  );
  const [isServiceValid, setIsServiceValid] = useState(
    formData.service_type.length > 0 ? true : false
  );
  const [isTagsValid, setIsTagsValid] = useState(
    formData.tags.length > 0 ? true : false
  );
  const [additionalTags, setAdditionalTags] = useState(formData.custom_tags);
  const [additionalTag, setAdditionalTag] = useState("");

  const StepValidation = () => {
    if (isTagsValid) {
      setIsStepValidate(true);
    } else {
      setIsStepValidate(false);
    }
  };

  const uploadFileStorage = async (files) => {
    if (files.length) {
      const imageRef = ref(storage, `twoclicksreview/${files[0].name + v4()}`);
      uploadBytes(imageRef, files[0]).then((snapshot) => {
        getDownloadURL(imageRef).then((logoUrl) => {
          if (logoUrl) {
            console.log(logoUrl);
            setFormData({ ...formData, logo: logoUrl });
            setLogo(logoUrl);
          }
        });
      });
    }
  };

  function Previews(props) {
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        uploadFileStorage(acceptedFiles);
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const thumbs = (
      <div>
        <div>
          <img src={logo} />
        </div>
      </div>
    );

    useEffect(() => {
      // uploadFile(files);
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);

    // useEffect(() => {
    //   uploadFile(files);
    // }, [files]);

    return (
      <section className="dropzone">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <span className="btn">Add files</span>
          <p className="allowed">Accepts .gif, .jpg, and .png</p>
          <p className="allowed">Less 2Mb and 1000px</p>
        </div>
        <aside>{thumbs}</aside>
      </section>
    );
  }

  const handleMessageChange = (e) => {
    if (e.target.value.length) {
      setIsMessageValid(true);
      setFormValidation({ ...formValidation, message: "" });
    } else {
      setFormValidation({ ...formValidation, message: "Empty message" });
    }
    setFormData({ ...formData, message: e.target.value });
  };

  const handleServiceChange = (e) => {
    if (e.target.value.length) {
      setIsServiceValid(true);
      setFormValidation({ ...formValidation, service_type: "" });
    } else {
      setFormValidation({ ...formValidation, service_type: "Empty message" });
    }
    setFormData({ ...formData, service_type: e.target.value });
  };

  const toggleTag = (tag) => {
    let tags = formData.tags;
    if (tags.indexOf(tag) > -1) {
      var index = tags.indexOf(tag);
      tags.splice(index, 1);
    } else {
      tags.push(tag);
    }
    if (tags.length) {
      setIsTagsValid(true);
    }
    setFormData({ ...formData, tags: tags });
    console.log(formData);
  };

  const removeAdditionalTag = (tag) => {
    let tags = additionalTags;
    var index = tags.indexOf(tag);
    tags.splice(index, 1);
    setAdditionalTags(tags);
    setFormData({
      ...formData,
      custom_tags: tags,
    });
  };

  const handleTagType = (e) => {
    // if (e.target.value.length) {
    console.log(additionalTag);
    setAdditionalTag(e.target.value);
    // }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value.length) {
      setAdditionalTags([...additionalTags, e.target.value]);
      setFormData({
        ...formData,
        custom_tags: [...additionalTags, e.target.value],
      });
      setAdditionalTag("");
    }
  };

  useEffect(() => {
    StepValidation();
  }, [isTagsValid]);

  return (
    <div className="add-form-step">
      <div className="tags_header file">
        <h2 className="title">{t('formStep2Title')}</h2>
        <Previews />
      </div>
      <div className="tags_header message">
        <h2 className="title"><Trans i18nKey="formStep2PersonalTitle" components={{ br: <br /> }} /></h2>
        <img src={tags_image} />
        <textarea
          name="message"
          id="message"
          title="message"
          required
          rows={4}
          value={formData.message}
          placeholder={t('formStep2PersonalMessage')}
          onChange={handleMessageChange}
        ></textarea>

        <h2 className="title" style={{ marginTop: "30px" }}>{t('formStep2CompanyTitle')}</h2>
        <input
          type="text"
          name="service-type"
          id="service-type"
          placeholder={t('formStep2CompanyType')}
          title="Service type"
          required
          value={formData.service_type}
          onChange={handleServiceChange}
        />
        <span className="instruction">{t('formStep2CompanyInstruction')}</span>
      </div>
      <div className="tags_header">
        <h2 className="title">{t('formStep2TagsTitle')}</h2>
        <h3 className="subtitle">{t('formStep2TagsSubtitle')}</h3>
      </div>
      <div className="tags">
        {/* <div className="title">Tags</div> */}
        <div className="list">
          {tags.map((item, i) => {
            let active = formData.tags.indexOf(item) > -1 ? "active" : "";
            return (
              <div
                className={"tag " + active}
                key={i}
                onClick={(e) => {
                  console.log("click", e.target.innerText);
                  toggleTag(e.target.innerText);
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <div className="additional-tags">
        <div className="title">{t('formStep2AdditionalTags')}</div>
        <div className="list">
          {additionalTags.map((item, i) => {
            let active = formData.tags.indexOf(item) > -1 ? "active" : "";
            return (
              <div
                className={"tag " + active}
                key={i}
                // onClick={(e) => {
                //   console.log("click", e.target.innerText);
                //   toggleTag(e.target.innerText);
                // }}
              >
                {item}
                <img
                  className="delete"
                  src={delete_icon}
                  onClick={() => {
                    removeAdditionalTag(item);
                  }}
                />
              </div>
            );
          })}
        </div>
        {additionalTags.length < additionalTagsMax ? (
          <>
            <input
              type="text"
              name="add-tag"
              id="add-tag"
              placeholder=" "
              title="Add tag"
              value={additionalTag}
              onChange={handleTagType}
              onKeyDown={handleKeyDown}
            />
            <span className="instruction">{t('formStep2AdditionalTagsInstruction')}</span>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default FormStep2;
