import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { RWebShare } from "react-web-share";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import result_step from "../Assets/Images/result_step.png";
import copy from "../Assets/Images/copy.svg";
import share from "../Assets/Images/share_black.svg";
import bookmark from "../Assets/Images/bookmark.svg";

function FormStep4({
  formData,
  setFormData,
  setIsStepValidate,
  formValidation,
  setFormValidation,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const languagePath = location.pathname.split('/')[1] === 'he' ? '/he/' : '/';

  const [url, setUrl] = useState(window.location.origin + languagePath  + formData.id);
  console.log("url", url, languagePath);

  useEffect(() => {
    console.log("url", url, languagePath);
  }, [url]);

  return (
    <div className="add-form-step">
      <div className="result_step">
        {/* <img src={result_step} /> */}
        <img className="logo" src={formData.logo} />
        <div className="actions_block">
          <div className="decription">{t('formStep4Message')}</div>

          <div className="actions">
            <img
              className="bookmark"
              src={bookmark}
              onClick={() => {
                alert(
                  "Press " +
                    (navigator.userAgent.toLowerCase().indexOf("mac") != -1
                      ? "Command/Cmd"
                      : "CTRL") +
                    " + D to bookmark this page."
                );
              }}
            />

            <RWebShare
              data={{
                text: "Share your personal review generator!",
                url: url,
                title: "2clicksreview",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <img className="share only_mobile" src={share} />
            </RWebShare>

            <img
              className="copy"
              src={copy}
              onClick={() => {
                navigator.clipboard.writeText(url);
                NotificationManager.info(t('formStep4Notification'));
              }}
            />
          </div>
        </div>
      </div>
      <NotificationContainer leaveTimeout="400" />
    </div>
  );
}

export default FormStep4;
