import React from "react";
import { useLocation } from "react-router-dom";
import '../i18n';
import { useTranslation } from 'react-i18next';
import footer_image from "../Assets/Images/footer_image.png";

function Footer(props) {
  const { t, i18n } = useTranslation();
  const href = i18n.language === 'he' ? '/he/contacts' : '/contacts';

  const steps = [1,2,3];
  const additional = (props.step > 0 || props.company ) ? 'abs' : '';

  const location = useLocation();
  const gemplan_link = location.pathname.split('/')[1] === 'he' ? 'https://www.gemplan.co.il/' : 'https://www.gem-plan.com/';
    return (
      <footer className={"footer " + additional}>
        {(steps.includes(props.step) || props.step === undefined) ? (<img src={footer_image} className='footer_image' />) : (<></>)}
        <div className="footer_block">
          <span className="dir_ltr">Made with ❤️ by <a href={gemplan_link} target='_blank'>Gemplan </a><br/>
         with chat GPT 🤖</span>
         <a href={href}>{t('contactUs')}</a>
         </div>
      </footer>
    );
  }
  
  export default Footer;
  