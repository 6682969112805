import React, { useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Main from "./Pages/Main";
import Company from './Pages/Company';
import Contacts from './Pages/Contacts';
import LanguageDetector from './LanguageDetector';
import { useTranslation } from 'react-i18next';
// import axios from "axios";
// import './App.css';

function App() {
  // const [prompt, setPrompt] = useState("");
  // const [response, setResponse] = useState("");

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Send a request to the server with the prompt
  //   axios
  //     .post("http://localhost:3003/chat", { prompt })
  //     .then((res) => {
  //       // Update the response state with the server's response
  //       console.log(res.data);
  //       setResponse(res.data.choices[0].text);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };
  const location = useLocation();
  const languagePath = location.pathname.split('/')[1] === 'he' ? 'he' : 'en';
  const languageClass = `lang-${languagePath}`;

  return (
    <>
      <LanguageDetector />
      <div className={languageClass}>
        <Routes>
          {/* English Routes */}
          <Route path="/" element={<Main />} />
          <Route path="/:company" element={<Company />} />
          <Route path="/contacts" element={<Contacts />} />

          {/* Hebrew Routes */}
          <Route path="/he" element={<Main />} />
          <Route path="/he/:company" element={<Company />} />
          <Route path="/he/contacts" element={<Contacts />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
