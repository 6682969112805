import React, { useState, useEffect } from "react";
import '../i18n';
import { useTranslation } from 'react-i18next';
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import {
    NotificationContainer,
    NotificationManager,
  } from "react-notifications";
  // import 'react-notifications/lib/notifications.css';
import "../Assets/Styles/App.scss";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import ContactsForm from "../Components/ContactsForm";

function Contacts() {  
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [key, setKey] = useState("");
  const [loader, setLoader] = useState(false);
  const [isStepValidate, setIsStepValidate] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formValidation, setFormValidation] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  // setKey(searchParams.get("key"));

  const ValidationMsg = (step) => {
    if(step === 0){
      if(!formData.name.length){
        setFormValidation({ ...formValidation, name: "Empty name" });
      }
      if(!formData.email.length){
        setFormValidation({ ...formValidation, email: "Incorrect email" });
      }
      if(!formData.message.length){
        setFormValidation({ ...formValidation, message: "Empty message" });
      }
    }
  };

  const sendEmail = async () => {
    const msg = `Name: ${formData.name}

Email: ${formData.email}
        
Phone: ${formData.phone}
    
Message: ${formData.message}`;
    let mailOptions = {
        to: "dev@gem-plan.com,lior@gemplan.co.il,gili@gemplan.co.il",
        subject: "2clicksreview contacts page",
        text: msg,
      };
      const responseemail = await fetch(
        "https://phpstack-964283-4107256.cloudwaysapps.com/send-email",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mailOptions),
        }
      );
      if (responseemail.ok) {
        NotificationManager.info(t('emailNotification'));
        console.log(t('emailNotification'));
      }

  };

  function StepNavigation() {
    switch (step) {
      case 0:
        return (
          <ContactsForm
            formData={formData}
            setFormData={setFormData}
            setIsStepValidate={setIsStepValidate}
            formValidation={formValidation}
            setFormValidation={setFormValidation}
          />
        );

      default:
        console.log("error");
        break;
    }
  }

  const ButtonName = (currentStep) => {
    switch (currentStep) {
      case 0:
        return t('contactsFormBtn');
      default:
        return t('contactsFormBtn');
    }
  };

  const StepButton = () => {
    switch (isStepValidate) {
      case false:
        return (
          <>
            {step < 3 ? (
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                onClick={() => {
                  console.log("not validated", formData, formValidation);
                  ValidationMsg(step);
                  // NotificationManager.info('234235235');
                }}
              >
                {ButtonName(step)}
              </motion.button>
            ) : (
              ""
            )}
          </>
        );

      case true:
        return (
          <>
            {step < 3 ? (
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                onClick={() => {
                    console.log("validated", formData, formValidation);
                    sendEmail();
                    // NotificationManager.info(t('emailNotification'));
                }}
              >
                {ButtonName(step)}
              </motion.button>
            ) : (
              ""
            )}
          </>
        );

      default:
        alert("error");
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>2clicksreview</title>
        <meta
          name="description"
          content="ביקורות אמיתיות קבלו של לקוחות
    ב- 2 קליקים בלבד"
        />
        <meta property="og:title" content="2clicksreview" />
        <meta
          property="og:description"
          content="ביקורות אמיתיות קבלו של לקוחות
      ב- 2 קליקים בלבד"
        />
        <meta
          property="og:image"
          content="https://2clicksreview.com/static/media/lets-start.9bdf9b8a1244e2d51fe9.png"
        />
      </Helmet>
      <NotificationContainer />
      <div className="desktop" step={step}>
        <div className="wrapper" step={step}>
          <Header step={step} />
          {StepNavigation()}
          {StepButton()}
          <Footer step={step} />
        </div>
      </div>
    </>
  );
}

export default Contacts;
