import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from './i18n'; // Your i18n configuration

const LanguageDetector = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the language code from the URL
    const langCode = location.pathname.split('/')[1] === 'he' ? 'he' : 'en';
    // Update i18next language
    i18n.changeLanguage(langCode);
    console.log('LanguageDetector', langCode);
  }, [location]); // Re-run when location changes

  return null; // This component does not render anything
};

export default LanguageDetector;
