import React from "react";
import logo from "../Assets/Images/2clicksreview.svg";
import logo_white from "../Assets/Images/2clicksreview-white.svg";
import checkmark from "../Assets/Images/checkmark.svg";
import share from "../Assets/Images/share.svg";

function Header(props) {
  const headerControls = () => {
    switch (props.step) {
      case 1:
        return (
          <div className="header_steps">
            <div className="step active">1</div>
            <div className="divider"></div>
            <div className="step">2</div>
          </div>
        );
      case 2:
        return (
          <div className="header_steps">
            <div className="step active">
              <img src={checkmark} />
            </div>
            <div className="divider active"></div>
            <div className="step active">2</div>
          </div>
        );
      case 3:
      case 4:
        return (
          <div className="header_share">
            <img src={share} />
          </div>
        );
    }
  };
  const header = () => {
    switch (props.step) {
      case 1:
      case 2:
        return (
          <header className="header abs" step={props.step} desktop={props.desktop}>
            <div className="header_logo">
              <img src={props.step === 0 ? logo : logo_white} alt="logo" />
            </div>
            {headerControls()}
          </header>
        );
      case 3:
      case 4:
        return <></>;
    }
  };

  return (
    <>{header()}</>
  );
}

export default Header;
