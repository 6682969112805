import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import '../i18n';
import { useTranslation } from 'react-i18next';
import lets_start from '../Assets/Images/lets-start.png';
import lets_start_en from '../Assets/Images/Mob_Eng_2clickReview.png';
import logo from "../Assets/Images/2clicksreview.svg";

function FormStep1({
  formData,
  setFormData,
  setIsStepValidate,
  formValidation,
  setFormValidation,
}) {
  const { t, i18n } = useTranslation();
  const href = i18n.language === 'he' ? '/he/' : '/';

  const [isNameValid, setIsNameValid] = useState(formData.company_name.length);
  const [isEmailValid, setIsEmailValid] = useState(formData.email.length);
  const [isLinkValid, setIsLinkValid] = useState(formData.link.length);

  const location = useLocation();
  const lets_start_image = location.pathname.split('/')[1] === 'he' ? lets_start : lets_start_en;

  const StepValidation = () => {
    console.log('step1',formData);
    if (isNameValid && isEmailValid && isLinkValid) {
      setIsStepValidate(true);
    } else {
      setIsStepValidate(false);
    }
  };

  const handleNameChange = (e) => {
    if (e.target.value.length) {
      setIsNameValid(true);
      setFormValidation({ ...formValidation, company_name: "" });
    } else {
      setFormValidation({ ...formValidation, company_name: "Empty company name" });
    }
    setFormData({ ...formData, company_name: e.target.value });
  };

  const handleEmailChange = (e) => {
    if (
      e.target.value
        .toLowerCase()
        .match(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        )
    ) {
      setIsEmailValid(true);
      setFormValidation({ ...formValidation, email: "" });
    } else {
      // setIsNameValid(false);
      setFormValidation({ ...formValidation, email: "Incorrect email" });
    }
    setFormData({ ...formData, email: e.target.value });
  };

  const handleLinkChange = (e) => {
    if (
      e.target.value
        .toLowerCase()
        .match('^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$')
    ) {
      setIsLinkValid(true);
      setFormValidation({ ...formValidation, link: "" });
    } else {
      setIsLinkValid(false);
      setFormValidation({ ...formValidation, link: "Incorrect link" });
    }
    setFormData({ ...formData, link: e.target.value });
  };

  useEffect(() => {
    StepValidation();
  }, [isNameValid, isEmailValid, isLinkValid]);

  return (
    <div className="add-form-step">
      <a href={href}><img className="main_logo" src={logo} alt="logo" /></a>
      <h2 className="title">{t('formStep1Title')}</h2>
      <h3 className="subtitle">{t('formStep1Subtitle')}</h3>
      <img className="header-image" src={lets_start_image} />
      <div className="form">
        <label>
          <input
            type="text"
            name="company-name"
            id="company-name"
            placeholder={t('formStep1CompanyName')}
            title="Company Name"
            required
            value={formData.company_name}
            onChange={handleNameChange}
          />
          {/* <span className="placeholder">Company Name</span> */}
          <p className="error_msg">{formValidation.company_name}</p>
        </label>
        <label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder={t('formStep1Email')}
            title="Email"
            required
            value={formData.email}
            onChange={handleEmailChange}
          />
          {/* <span className="placeholder">Email</span> */}
          <p className="error_msg">{formValidation.email}</p>
        </label>
        <label>
          <input
            type="text"
            name="website-link"
            id="website-link"
            placeholder={t('formStep1WebsiteLink')}
            title="website Link"
            required
            value={formData.link}
            onChange={handleLinkChange}
          />
          {/* <span className="placeholder">Website Link</span> */}
          <p className="error_msg">{formValidation.link}</p>
        </label>
      </div>
    </div>
  );
}

export default FormStep1;
