import React, { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import '../i18n';
import { useTranslation, Trans } from 'react-i18next';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import result_step from "../Assets/Images/result_step.png";
import copy from "../Assets/Images/copy.svg";
import google from "../Assets/Images/google.svg";
import facebook from "../Assets/Images/facebook.svg";
import yelp from "../Assets/Images/yelp.svg";
import tripadvisor from "../Assets/Images/tripadvisor.svg";
import g2 from "../Assets/Images/g2.svg";
import amazon from "../Assets/Images/amazon.svg";
import trustpilot from "../Assets/Images/trustpilot.svg";
import angi from "../Assets/Images/angi.svg";
import bbb from "../Assets/Images/bbb.svg";
import foursquare from "../Assets/Images/foursquare.svg";
import email from "../Assets/Images/email.svg";

function CompanyStep2(props) {
  const { t } = useTranslation();

  const [url, setUrl] = useState(window.location.origin + "/");

  // useEffect(() => {
  //   navigator.clipboard.writeText(props.response);
  //   // navigator.clipboard.writeText('test copy');
  //   NotificationManager.info(t('companyStep2Notification'));
  // }, []);

  const Actions = () => (
    <div id="results" className="search-results">
      Some Results
    </div>
  )
  
  const sendEmail = async () => {
    let mailOptionsUser = {
      to: props.company.review_email,
      subject: t('emailSubjectReview'),
      text: props.response,
    };
    const responseemailuser = await fetch(
      "https://phpstack-964283-4107256.cloudwaysapps.com/send-email",
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mailOptionsUser),
      }
    );
  };

  return (
    <div className="add-form-step">
      <div className="result_step last">
        <img className="accent" src={result_step} />
        <img className="logo" src={props.company.logo} />
        <div className="title"><Trans i18nKey="companyStep2Title" components={{ br: <br /> }} /></div>
        <div className="actions_block">
          <div className="decription">{props.response}</div>
          {/* <div className="decription">תודה על טיפולכם. השרות שקיבלתי לכל אורך הדרך היה מצוין! מקווה שבטיסותיי הבאות אעשה רק ביטוח ולא יהיה צורך להגיש תביעה בגין ביטול טיסה...</div> */}
          {props.showCopy ? (
            <div className="actions">
              <img
                className="copy"
                src={copy}
                onClick={() => {
                  navigator.clipboard.writeText(props.response);
                  NotificationManager.info(t('companyStep2Notification'));
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="notice">{t('companyStep2Notice')}</div>

        <div className="subtitle"><Trans i18nKey="companyStep2Subtitle" components={{ br: <br /> }} /></div>
        <div className="socials">
        {/* {
    google: "",
    facebook: "",
    yelp: "",
    tripadvisor: "",
    g2: "",
    amazon: "",
    trustpilot: "",
    angi: "",
    bbb: "",
    foursquare: "",
    email: "",
  } */}
          {props.company.google ? (
            <a href={props.company.google} className="social" target="_blank">
              <img src={google} />
            </a>
          ) : (
            <></>
          )}
          {props.company.facebook ? (
            <a href={props.company.facebook} className="social" target="_blank">
              <img src={facebook} />
            </a>
          ) : (
            <></>
          )}
          {props.company.yelp ? (
            <a href={props.company.yelp} className="social" target="_blank">
              <img src={yelp} />
            </a>
          ) : (
            <></>
          )}
          {props.company.tripadvisor ? (
            <a
              href={props.company.tripadvisor}
              className="social"
              target="_blank"
            >
              <img src={tripadvisor} />
            </a>
          ) : (
            <></>
          )}          
          {props.company.g2 ? (
            <a
              href={props.company.g2}
              className="social"
              target="_blank"
            >
              <img src={g2} />
            </a>
          ) : (
            <></>
          )}          
          {props.company.amazon ? (
            <a
              href={props.company.amazon}
              className="social"
              target="_blank"
            >
              <img src={amazon} />
            </a>
          ) : (
            <></>
          )}          
          {props.company.trustpilot ? (
            <a
              href={props.company.trustpilot}
              className="social"
              target="_blank"
            >
              <img src={trustpilot} />
            </a>
          ) : (
            <></>
          )}          
          {props.company.angi ? (
            <a
              href={props.company.angi}
              className="social"
              target="_blank"
            >
              <img src={angi} />
            </a>
          ) : (
            <></>
          )}          
          {props.company.bbb ? (
            <a
              href={props.company.bbb}
              className="social"
              target="_blank"
            >
              <img src={bbb} />
            </a>
          ) : (
            <></>
          )}          
          {props.company.foursquare ? (
            <a
              href={props.company.foursquare}
              className="social"
              target="_blank"
            >
              <img src={foursquare} />
            </a>
          ) : (
            <></>
          )}
          {props.company.review_email ? (
            <div
              className="social email"
              onClick={() => {
                sendEmail();
                NotificationManager.info(t('emailNotificationSent'));
            }}
            >
              <img src={email} />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="info">{t('companyStep2Info')}</div>
      </div>
      <NotificationContainer />
    </div>
  );
}

export default CompanyStep2;
