import React, { useEffect, useState } from "react";
import '../i18n';
import { useTranslation } from 'react-i18next';
import { Dna } from "react-loader-spinner";

function FormStep3({
  formData,
  setFormData,
  setIsStepValidate,
  formValidation,
  setFormValidation,
  loader,
}) {
  const { t } = useTranslation();

  const [links, setLinks] = useState({
    google: "",
    facebook: "",
    yelp: "",
    tripadvisor: "",
    g2: "",
    amazon: "",
    trustpilot: "",
    angi: "",
    bbb: "",
    foursquare: "",
    email: "",
  });

  const StepValidation = () => {
    console.log(links);
    const isAnyLinkNotEmpty = Object.values(links).some(value => value.trim() !== "");
    if (isAnyLinkNotEmpty) {
      setIsStepValidate(true);
      console.log("validated");
    } else {
      setIsStepValidate(false);
      console.log("not validated");
    }
  };

  const handleLinkChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setLinks({ ...links, [e.target.name]: e.target.value });
    console.log('formData.google', [formData.google]);
    if (
      formData.google === "" &&
      formData.facebook === "" &&
      formData.review_email === ""
    ) {
      setFormValidation({
        ...formValidation,
        google: t('formStep3Notification'),
      });
    } else {
      setFormValidation({ ...formValidation, google: "" });
    }
  };

  useEffect(() => {
    StepValidation();
  }, [links]);

  return (
    <div className="add-form-step">
      <div className="tags_header links">
        <h2 className="title">{t('formStep3Title')}</h2>
        <h3 className="subtitle">{t('formStep3Subtitle')}</h3>
      </div>
      <div className="links">
        <label>
          <input
            type="text"
            name="google"
            id="google"
            placeholder={t('formStep3Google')}
            title="google"
            required
            value={formData.google}
            onChange={handleLinkChange}
          />
          <p className="error_msg">{formValidation.google}</p>
        </label>
        <input
          type="text"
          name="facebook"
          id="facebook"
          placeholder={t('formStep3Facebook')}
          title="facebook"
          required
          value={formData.facebook}
          onChange={handleLinkChange}
        />        
        <input
          type="text"
          name="yelp"
          id="yelp"
          placeholder={t('formStep3Yelp')}
          title="yelp"
          required
          value={formData.yelp}
          onChange={handleLinkChange}
        />        
        <input
          type="text"
          name="tripadvisor"
          id="tripadvisor"
          placeholder={t('formStep3Tripadvisor')}
          title="tripadvisor"
          required
          value={formData.tripadvisor}
          onChange={handleLinkChange}
        />        
        <input
          type="text"
          name="g2"
          id="g2"
          placeholder={t('formStep3G2')}
          title="g2"
          required
          value={formData.g2}
          onChange={handleLinkChange}
        />        
        <input
          type="text"
          name="amazon"
          id="amazon"
          placeholder={t('formStep3Amazon')}
          title="amazon"
          required
          value={formData.amazon}
          onChange={handleLinkChange}
        />        
        <input
          type="text"
          name="trustpilot"
          id="trustpilot"
          placeholder={t('formStep3Trustpilot')}
          title="trustpilot"
          required
          value={formData.trustpilot}
          onChange={handleLinkChange}
        />        
        <input
          type="text"
          name="angi"
          id="angi"
          placeholder={t('formStep3Angi')}
          title="angi"
          required
          value={formData.angi}
          onChange={handleLinkChange}
        />        
        <input
          type="text"
          name="bbb"
          id="bbb"
          placeholder={t('formStep3bbb')}
          title="bbb"
          required
          value={formData.bbb}
          onChange={handleLinkChange}
        />        
        <input
          type="text"
          name="foursquare"
          id="foursquare"
          placeholder={t('formStep3Foursquare')}
          title="foursquare"
          required
          value={formData.foursquare}
          onChange={handleLinkChange}
        />
        <input
          type="text"
          name="review_email"
          id="email"
          placeholder={t('formStep3Email')}
          title="email"
          required
          value={formData.review_email}
          onChange={handleLinkChange}
        />
      </div>

      {loader ? (
        <div className="loader-wrapper">
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default FormStep3;
