import React, { useEffect, useState } from "react";
import { Dna } from "react-loader-spinner";
import '../i18n';
import { useTranslation } from 'react-i18next';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import result_step from "../Assets/Images/result_step.png";
import copy from "../Assets/Images/copy.svg";

function CompanyStep1({
  company,
  loader,
  tags,
  setTags,
  isTagsValid,
  setIsTagsValid
}) {
  const { t } = useTranslation();

  const [url, setUrl] = useState(window.location.origin + "/");
  // const [isTagsValid, setIsTagsValid] = useState(false);
  // const [tags, setTags] = useState([]);


  let all_tags = [];
  if (company?.tags && company?.custom_tags) {
    all_tags = [...company?.tags, ...company?.custom_tags];
  }

  const toggleTag = (tag) => {
    // let curr_tags = tags;
    console.log('toggle', tag);
    console.log('find', tags.indexOf(tag));
    if (tags.indexOf(tag) > -1) {
      var index = tags.indexOf(tag);
      tags.splice(index, 1);
    } else {
      tags.push(tag);
    }
    console.log('tags', tags);
    if (tags.length) {
      setIsTagsValid(true);
    } else {
      setIsTagsValid(false);
    }
    // setTags(tags);
    setTags([...tags]);
    // setTags(tags);
  };

  useEffect(() => {
    console.log('chosenTags', tags);
  }, [tags]);

  const tagsNotChoose = !isTagsValid ? 'not_choose' : ''; 

  return (
    <div className="add-form-step">
      <div className="company_step">
        <img className="accent" src={result_step} />
        <img className="logo" src={company?.logo} />
        <div className="link_block">
          <img
            className="copy"
            src={copy}
            onClick={() => {
              navigator.clipboard.writeText(company?.link);
              NotificationManager.info(t("companyStep1Notification"));
            }}
          />
          <a href={company?.link} target="_blank">
            {company?.link}
          </a>
        </div>
        <div className="message">{company?.message}</div>
        <div className="tags_block">
          <h3 className="tags_title">{t('companyStep1Title')}</h3>
          <span className="tags_hint">{t('companyStep1Subtitle')}</span>
          <div className={"tags " + tagsNotChoose}>
            {all_tags.map((item, i) => {
               let active = tags.indexOf(item) > -1 ? "active" : "";
              return (
                <div
                  className={"tag " + active}
                  key={i}
                  onClick={(e) => {
                    console.log(e.target.innerText);
                    toggleTag(e.target.innerText);
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {loader ? (
        <div className="loader-wrapper">
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CompanyStep1;
