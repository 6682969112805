import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { db } from "../firebase-config";
import { useParams } from "react-router-dom";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import "../i18n";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import "../Assets/Styles/App.scss";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import CompanyStep1 from "../Components/CompanyStep1";
import CompanyStep2 from "../Components/CompanyStep2";

function Company() {
  const { t } = useTranslation();

  const [company, setCompany] = useState({});
  const [step, setStep] = useState(0);
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [showCopy, setShowCopy] = useState(false);
  const [loader, setLoader] = useState(false);
  const [chosenTags, setChosenTags] = useState([]);
  const [isTagsValid, setIsTagsValid] = useState(false);

  const params = useParams();

  const sendEmails = async (company, prompt, answer) => {
    let content = { ...company, prompt: prompt, review: answer };

    let mailOptions = {
      to: "dev@gem-plan.com,lior@gemplan.co.il",
      subject: "2clicksreview admin notification",
      text: JSON.stringify(content),
    };
    const responseemail = await fetch(
      "https://phpstack-964283-4107256.cloudwaysapps.com/send-email",
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mailOptions),
      }
    );
  };

  const generateReview = async () => {
    const responseapi = await fetch(
      "https://phpstack-964283-4107256.cloudwaysapps.com/chat",
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt }),
      }
    );
    if (!responseapi.ok || !responseapi.body) {
      throw responseapi.statusText;
    }

    // Here we start prepping for the streaming response
    const reader = responseapi.body.getReader();
    const decoder = new TextDecoder();
    const loopRunner = true;

    let answer = "";

    while (loopRunner) {
      // Here we start reading the stream, until its done.
      const { value, done } = await reader.read();
      if (done) {
        // console.log('-----done', answer);
        setShowCopy(true);
        sendEmails(company, prompt, answer);

        const reviewRef = collection(db, "reviews");
        const data = {
          name: company.company_name,
          prompt: prompt,
          review: answer,
          date: Date().toLocaleString(),
        };
        addDoc(reviewRef, data)
          .then((response) => {
            console.log("add review", answer);
          })
          .catch((error) => {
            console.log(error);
          });

        navigator.clipboard.writeText(answer);
        NotificationManager.info(t("companyStep2Notification"));
        break;
      }
      const decodedChunk = decoder.decode(value, { stream: true });
      answer = answer + decodedChunk;
      setResponse((response) => response + decodedChunk); // update state with new chunk
      // console.log(decodedChunk, answer);
    }

    // setLoader(true);
    // // Send a request to the server with the prompt
    // //https://clicksreview.ew.r.appspot.com
    // axios
    //   .post("/chat", { prompt })
    //   .then((res) => {
    //     // Update the response state with the server's response
    //     console.log(res.data);
    //     console.log(res.data.choices[0].text);
    //     let answer = res.data.choices[0].text;
    //     setResponse(answer);
    //     setLoader(false);

    //     setStep((step) => step + 1);

    //     sendEmails(company, prompt, answer);

    //     const reviewRef = collection(db, "reviews");
    //     const data = {
    //       name: company.company_name,
    //       prompt: prompt,
    //       review: answer,
    //       date: Date().toLocaleString(),
    //     };
    //     addDoc(reviewRef, data)
    //       .then((response) => {
    //         console.log("add review", response);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   })
    //   .catch((err) => {
    //     setLoader(false);
    //     console.error(err);
    //   });
  };

  useEffect(() => {
    const getUsers = async () => {
      const usersRef = collection(db, "users");

      const search_user = query(
        usersRef,
        where("__name__", "==", params.company)
      );
      // console.log("search_board", search_board);

      const data = await getDocs(search_user);
      data.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        setCompany(doc.data());
        let tags = [...doc.data()?.tags, ...doc.data()?.custom_tags];
        // setPrompt(
        //   "לכתוב ביקורת על שירות זה " +
        //     doc.data().link +
        //     " מבוסס על תגיות זה " +
        //     tags.join(", ")
        // );
      });
    };

    getUsers();
  }, []);
  useEffect(() => {
    console.log("prompt", prompt);
  }, [prompt]);
  useEffect(() => {
    console.log("chosenTags0", chosenTags);
    setPrompt(
      t("companyPrompt1") +
        company.company_name +
        t("companyPrompt2") +
        company.service_type +
        t("companyPrompt3") +
        chosenTags.join(", ") +
        t("companyPrompt4")
    );
    //     setPrompt(
    //       " תכתוב בקורת חיובית לחברת " +
    //         company.service_type +
    //         " דיגיטלי לפי המידע הבא: שם: " +
    //         company.company_name +
    //         " התמקד בנושאים " +
    //         chosenTags.join(", ") +
    //         " ביקורת:"
    //     );
  }, [chosenTags]);
  useEffect(() => {
    console.log("isTagsValid", isTagsValid);
  }, [isTagsValid]);

  function StepNavigation() {
    switch (step) {
      case 0:
        return (
          <CompanyStep1
            company={company}
            loader={loader}
            tags={chosenTags}
            setTags={setChosenTags}
            isTagsValid={isTagsValid}
            setIsTagsValid={setIsTagsValid}
          />
        );

      case 1:
        return (
          <CompanyStep2
            company={company}
            response={response}
            showCopy={showCopy}
          />
        );

      default:
        console.log("error");
        break;
    }
  }

  const StepButton = () => {
    if (step === 0) {
      if (isTagsValid) {
        return (
          <button
            onClick={() => {
              generateReview();
              setStep((step) => step + 1);
            }}
          >
            {t("company1ButtonName")}
          </button>
        );
      } else {
        return <button>{t("company2ButtonName")}</button>;
      }
    }
    // switch (step) {
    //   case 0:
    //     return (
    //       <button
    //         onClick={() => {
    //           generateReview();
    //         }}
    //       >
    //         צור לי ביקורת
    //       </button>
    //     );

    //   default:
    //     break;
    // }
  };

  return (
    <>
      <Helmet>
        <title>{company.company_name}</title>
        <meta property="og:title" content={company.company_name} />
        <meta name="description" content="שני קליקים והביקורת שלך מוכנה" />
        <meta
          property="og:description"
          content="שני קליקים והביקורת שלך מוכנה"
        />
        {company.logo ? (
          <meta property="og:image" content={company.logo} />
        ) : (
          <meta
            property="og:image"
            content="https://2clicksreview.com/static/media/lets-start.9bdf9b8a1244e2d51fe9.png"
          />
        )}
      </Helmet>
      <div className="desktop" step={step + 1}>
        <div className="wrapper">
          <Header step={step + 1} desktop={1} />
          {StepNavigation()}
          {StepButton()}
          <Footer company={true} />
        </div>
      </div>
    </>
  );
}

export default Company;
