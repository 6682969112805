import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import '../i18n';
import { useTranslation } from 'react-i18next';
import lets_start from '../Assets/Images/lets-start.png';
import lets_start_en from '../Assets/Images/Mob_Eng_2clickReview.png';
import logo from "../Assets/Images/2clicksreview.svg";

function ContactsForm({
  formData,
  setFormData,
  setIsStepValidate,
  formValidation,
  setFormValidation,
}) {
  const { t, i18n } = useTranslation();
  const href = i18n.language === 'he' ? '/he/' : '/';
  console.log(t('contactsFormName'));

  const [isNameValid, setIsNameValid] = useState(formData.name.length);
  const [isEmailValid, setIsEmailValid] = useState(formData.email.length);
  const [isMessageValid, setIsMessageValid] = useState(formData.message.length);

  const location = useLocation();
  const lets_start_image = location.pathname.split('/')[1] === 'he' ? lets_start : lets_start_en;

  const StepValidation = () => {
    console.log('step1',formData);
    if (isNameValid && isEmailValid && isMessageValid) {
      setIsStepValidate(true);
    } else {
      setIsStepValidate(false);
    }
  };

  const handleNameChange = (e) => {
    if (e.target.value.length) {
      setIsNameValid(true);
      setFormValidation({ ...formValidation, name: "" });
    } else {
        setIsStepValidate(false);
      setFormValidation({ ...formValidation, name: "Empty company name" });
    }
    setFormData({ ...formData, name: e.target.value });
  };

  const handleEmailChange = (e) => {
    if (
      e.target.value
        .toLowerCase()
        .match(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        )
    ) {
      setIsEmailValid(true);
      setFormValidation({ ...formValidation, email: "" });
    } else {
      setIsStepValidate(false);
      setFormValidation({ ...formValidation, email: "Incorrect email" });
    }
    setFormData({ ...formData, email: e.target.value });
  };
  const handlePhoneChange = (e) => {
    setFormData({ ...formData, phone: e.target.value });
  };
  const handleMessageChange = (e) => {
    if (e.target.value.length) {
      setIsMessageValid(true);
      setFormValidation({ ...formValidation, message: "" });
    } else {
        setIsStepValidate(false);
      setFormValidation({ ...formValidation, message: "Incorrect Message" });
    }
    setFormData({ ...formData, message: e.target.value });
  };

  useEffect(() => {
    StepValidation();
  }, [isNameValid, isEmailValid, isMessageValid]);

  return (
    <div className="add-form-step">
      <a href={href}><img className="main_logo" src={logo} alt="logo" /></a>
      <h2 className="title">{t('contactsFormTitle')}</h2>
      <img className="header-image" src={lets_start_image} />
      <div className="form">
        <label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder={t('contactsFormName')}
            title="Name"
            required
            value={formData.name}
            onChange={handleNameChange}
          />
          {/* <span className="placeholder">Name</span> */}
          <p className="error_msg">{formValidation.name}</p>
        </label>
        <label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder={t('contactsFormEmail')}
            title="Email"
            required
            value={formData.email}
            onChange={handleEmailChange}
          />
          {/* <span className="placeholder">Email</span> */}
          <p className="error_msg">{formValidation.email}</p>
        </label>        
        <label>
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder={t('contactsFormPhone')}
            title="Phone"
            required
            value={formData.phone}
            onChange={handlePhoneChange}
            pattern="[+]?[0-9]{1,3}[0-9]{10}"
            />
        </label>
        <label>
          <textarea
            type="text"
            name="message"
            id="message"
            placeholder={t('contactsFormMessage')}
            title="message"
            required
            value={formData.message}
            onChange={handleMessageChange}
            rows="4"
          ></textarea>
          {/* <span className="placeholder">Website Message</span> */}
          <p className="error_msg">{formValidation.message}</p>
        </label>
      </div>
    </div>
  );
}

export default ContactsForm;
