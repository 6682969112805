import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import '../i18n';
import { useTranslation } from 'react-i18next';
import { db } from "../firebase-config";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { sha256 } from "crypto-hash";
import { motion } from "framer-motion";
import "../Assets/Styles/App.scss";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import FormStep1 from "../Components/FormStep1";
import FormStep2 from "../Components/FormStep2";
import FormStep3 from "../Components/FormStep3";
import FormStep4 from "../Components/FormStep4";

function Main() {  
  const location = useLocation();
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [key, setKey] = useState("");
  const [loader, setLoader] = useState(false);
  const [isStepValidate, setIsStepValidate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    company_name: "",
    email: "",
    link: "",
    logo: "",
    message: "",
    service_type: "",
    tags: [],
    custom_tags: [],
    google: "",
    facebook: "",
    review_email: "",
    key: "",
    date: Date().toLocaleString(),
  });
  const [formValidation, setFormValidation] = useState({
    company_name: "",
    email: "",
    link: "",
    message: "",
    service_type: "",
    tags: "",
    google: t('formStep3Notification'),
    facebook: "",
    review_email: "",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  // setKey(searchParams.get("key"));

  const ValidationMsg = (step) => {
    if(step === 0){
      if(!formData.link.length){
        setFormValidation({ ...formValidation, link: "Incorrect link" });
      }
      if(!formData.email.length){
        setFormValidation({ ...formValidation, email: "Incorrect email" });
      }
      if(!formData.company_name.length){
        setFormValidation({ ...formValidation, company_name: "Empty company name" });
      }
    }
  };

  const sendEmails = async (email, id, key) => {
    const languagePath = location.pathname.split('/')[1] === 'he' ? '/he/' : '/';

    var templateParams = {
      email: email,
      link: window.location.origin + "/" + id,
      editlink: window.location.origin + "/?key=" + key,
    };

    const msg = `${t('emailMessageLine1')}

${t('emailMessageLine2')} ${window.location.origin + languagePath + id}
    
${t('emailMessageLine3')} ${window.location.origin + languagePath +"?key=" + key}`;
    let mailOptionsUser = {
      to: email,
      subject: t('emailSubject'),
      text: msg,
    };
    const responseemailuser = await fetch(
      "https://phpstack-964283-4107256.cloudwaysapps.com/send-email",
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mailOptionsUser),
      }
    );

    let mailOptions = {
      to: "dev@gem-plan.com,lior@gemplan.co.il",
      subject: "2clicksreview admin notification",
      text: JSON.stringify(formData),
    };
    const responseemail = await fetch(
      "https://phpstack-964283-4107256.cloudwaysapps.com/send-email",
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mailOptions),
      }
    );
  };

  const saveFormData = () => {
    setLoader(true);
    const userRef = collection(db, "users");

    if (formData.id === "") {
      addDoc(userRef, formData)
        .then((response) => {
          setLoader(false);
          console.log("update doc", response);
          setFormData({ ...formData, id: response.id });
          setStep((step) => step + 1);
          sendEmails(formData.email, response.id, formData.key);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const currUserRef = doc(db, "users", formData.id);
      console.log("need update");
      updateDoc(currUserRef, formData).then((response) => {
        console.log("update doc", response);
        setLoader(false);
        setStep((step) => step + 1);
      });
    }
  };

  function StepNavigation() {
    switch (step) {
      case 0:
        return (
          <FormStep1
            formData={formData}
            setFormData={setFormData}
            setIsStepValidate={setIsStepValidate}
            formValidation={formValidation}
            setFormValidation={setFormValidation}
          />
        );

      case 1:
        return (
          <FormStep2
            formData={formData}
            setFormData={setFormData}
            setIsStepValidate={setIsStepValidate}
            formValidation={formValidation}
            setFormValidation={setFormValidation}
          />
        );

      case 2:
        return (
          <FormStep3
            formData={formData}
            setFormData={setFormData}
            setIsStepValidate={setIsStepValidate}
            formValidation={formValidation}
            setFormValidation={setFormValidation}
            loader={loader}
          />
        );

      case 3:
        return (
          <FormStep4
            formData={formData}
            setFormData={setFormData}
            setIsStepValidate={setIsStepValidate}
            formValidation={formValidation}
            setFormValidation={setFormValidation}
          />
        );

      default:
        console.log("error");
        break;
    }
  }

  const ButtonName = (currentStep) => {
    switch (currentStep) {
      case 0:
        return t('formStep1ButtonName');
      case 2:
        return t('formStep2ButtonName');
      default:
        return t('formStep3ButtonName');
    }
  };

  const StepButton = () => {
    switch (isStepValidate) {
      case false:
        return (
          <>
            {step < 3 ? (
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                onClick={() => {
                  console.log("not validated", formData, formValidation);
                  ValidationMsg(step);
                }}
              >
                {ButtonName(step)}
              </motion.button>
            ) : (
              ""
            )}

            {step != 0 && step < 3 ? (
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="back_btn"
                onClick={() => {
                  setStep((step) => step - 1);
                  setIsStepValidate(true);
                }}
              >
                {t('backBtn')}
              </motion.div>
            ) : (
              ""
            )}
          </>
        );

      case true:
        return (
          <>
            {step < 3 ? (
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                onClick={() => {
                  if (step < 2) {
                    setStep((step) => step + 1);
                    setIsStepValidate(false);
                  }
                  if (step === 2) {
                    console.log(formData);
                    saveFormData();
                    // setStep((step) => step + 1);
                  }
                }}
              >
                {ButtonName(step)}
              </motion.button>
            ) : (
              ""
            )}

            {step != 0 && step < 3 ? (
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="back_btn"
                onClick={() => {
                  setStep((step) => step - 1);
                  setIsStepValidate(true);
                }}
              >
                {t('backBtn')}
              </motion.div>
            ) : (
              ""
            )}
          </>
        );

      default:
        alert("error");
        break;
    }
  };

  const getCompany = async () => {
    const companyRef = collection(db, "users");

    const search_company = query(companyRef, where("key", "==", key));
    // console.log("search_board", search_board);

    const data = await getDocs(search_company);
    data.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      setStep(1);
      setFormData({
        id: doc.id,
        company_name: doc.data().company_name,
        email: doc.data().email,
        link: doc.data().link,
        logo: doc.data().logo,
        message: doc.data().message,
        service_type: doc.data().service_type,
        tags: doc.data().tags,
        custom_tags: doc.data().custom_tags,
        google: doc.data().google,
        facebook: doc.data().facebook,
        linkedin: doc.data().linkedin,
        instagram: doc.data().instagram,
        review_email: doc.data().review_email,
        key: doc.data().key,
        date: Date().toLocaleString(),
      });
      setIsStepValidate(true);
    });
    console.log("companies", data.docs);
  };

  useEffect(() => {
    if (searchParams.has("key")) {
      setKey(searchParams.get("key"));
      getCompany();
    } else {
      sha256(Date().toLocaleString()).then((response) => {
        // setKey(response);
        setFormData({ ...formData, key: response });
      });
    }
    console.log("key", key);
  }, [key]);

  useEffect(() => {
    console.log("formData by key", formData);
  }, [formData]);

  return (
    <>
      <Helmet>
        <title>2clicksreview</title>
        <meta
          name="description"
          content="ביקורות אמיתיות קבלו של לקוחות
    ב- 2 קליקים בלבד"
        />
        <meta property="og:title" content="2clicksreview" />
        <meta
          property="og:description"
          content="ביקורות אמיתיות קבלו של לקוחות
      ב- 2 קליקים בלבד"
        />
        <meta
          property="og:image"
          content="https://2clicksreview.com/static/media/lets-start.9bdf9b8a1244e2d51fe9.png"
        />
      </Helmet>
      <div className="desktop" step={step}>
        <div className="wrapper" step={step}>
          <Header step={step} />
          {StepNavigation()}
          {StepButton()}
          <Footer step={step} />
        </div>
      </div>
    </>
  );
}

export default Main;
